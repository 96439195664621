import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CategoryIcon from "@material-ui/icons/Category";
import { BiEdit } from "react-icons/bi";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { localApi } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdateMetal(props) {
  const [items, setItems] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [metdesc, setMetalDesc] = React.useState("");
  const [different, setDifferent] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [metErr, setMetErr] = React.useState("");
  const [differentErr, setDifferentErr] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [gstErr, setGstErr] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getSets() {
    const api = localApi + `/rawprice/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems(getResult && getResult);
    // console.log(getResult.metalData);
  }

  useEffect(() => {
    getSets();
  }, []);

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    setMetalDesc(item.title);
    setDifferent(item.different);
    setPrice(item.price);
    setGst(item.taxPercentage);
    setGetId(item.id);
  };

  const SubmitUpdateMetal = async (e) => {
    let errorCount = 0;
    if (metdesc === "" || !metdesc) {
      errorCount++;
      setMetErr("Pipe/Golwire is Required");
    }

    // if (hsncode === "" || !hsncode) {
    //   errorCount++;
    //   setHsncodeErr("HSN Code is Required");
    // }

    if (different === "" || !different) {
      errorCount++;
      setDifferentErr("Different Price is Required");
    }

    if (price === "" || !price) {
      errorCount++;
      setPriceErr("Price is Required");
    }

    if (gst === "" || !gst) {
      errorCount++;
      setGstErr("GST is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.id,
          title: metdesc,
          different: different,
          price: price,
          taxPercentage: gst,
        }),
      };

      fetch(localApi + `/rawprice/update/rawpriceById/${getId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Raw Metal</small>
              </h3>
            </CardHeader>

            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Updated Raw Metal</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Raw Metals Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Raw Metal Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Pipe/Golwire Thaw..."
                    id="metdesc"
                    inputProps={{
                      placeholder: "Pipe/Golwire Thaw...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metdesc"
                    value={metdesc}
                    onChange={(e) => {
                      setMetalDesc(e.target.value);
                      setMetErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{metErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Metal Price..."
                    id="price"
                    inputProps={{
                      placeholder: "Metal Price ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{priceErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Different Price..."
                    id="different"
                    inputProps={{
                      placeholder: "Different Price...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="different"
                    value={different}
                    onChange={(e) => {
                      setDifferent(e.target.value);
                      setDifferentErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{differentErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="GST @18%..."
                    id="gst"
                    inputProps={{
                      placeholder: "GST @18%...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gst"
                    value={gst}
                    onChange={(e) => {
                      setGst(e.target.value);
                      setGstErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{gstErr}</span>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitUpdateMetal}
                className={classes.updateProfileButton}
              >
                Update
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Update</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitUpdateMetal}
                  className={classes.updateProfileButton}
                >
                  Update
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
