import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import CardIcon from "components/Card/CardIcon.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";

// material-ui icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
// import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Follower() {
  const [addCart, setAddCart] = React.useState([]);
  const [follower, setFollwer] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function arrSort(arr) {
    arr.sort((a, b) => a - b);
    arr.reverse();
    return arr;
  }

  async function getFollower() {
    const api = localApi + `/visitor/list/byFollowId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    console.log(getResult);

    const grouped = [];
    getResult.forEach((it) => {
      const existingReg = grouped.map((it) => it.regId);

      if (existingReg.includes(it.regId)) {
        const existingReg = grouped.find((ex) => ex.regId === it.regId);
        // existingReg.aId += it.aId;
        // existingReg.rId += it.rId;
      } else {
        grouped.push({
          ...it,
          regId: it.regId,
        });
      }
    });

    setFollwer(arrSort(grouped && grouped));
    // console.log(grouped);
  }

  useEffect(() => {
    getFollower();
  }, []);

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <i className="fab fa-twitter" />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Follower</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
        {follower &&
          follower.map((Ele) => (
            <GridItem xs={12} sm={6} md={6} key={Ele.mobile}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">{Ele.type}</CardIcon>
                  {/* <h3 className={classes.cardIconTitle}>
                  <small>Select Design Images</small>
                </h3> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <span>
                        <strong>{Ele.firstName + " " + Ele.lastName}</strong>
                      </span>
                      <br />
                      <span>Company</span>
                      <br />
                      <sapn>Mobile</sapn>
                      <br />
                      <span>WhatsApp</span>
                      <br />
                      <span>City</span>
                      <br />
                      <span>Last Update</span>
                      <br />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <span></span>
                      <br />
                      <span>{Ele.company}</span>
                      <br />
                      <ImMobile />
                      &nbsp;&nbsp;
                      <span>
                        <a href={`tel: ${Ele.mobile}`}>{Ele.mobile}</a>
                      </span>
                      <br />
                      <IoLogoWhatsapp color="green" />
                      &nbsp;&nbsp;
                      <span>
                        <a
                          href={`https://api.whatsapp.com/send/?phone=${Ele.whatsapp}&text=Hello&app_absent=0`}
                        >
                          {Ele.whatsapp}
                        </a>
                      </span>
                      <br />
                      <span>{Ele.city}</span>
                      <br />
                      <span>{Ele.visitDate.slice(0, -19)}</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <span style={{ color: "#d81b60" }}>{Ele.lastDays}</span>
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
