import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, useLocation } from "react-router-dom";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useStyl = makeStyles(styDrop);

export default function ViewBranch(props) {
  const [transList, setTransList] = React.useState([]);
  const [addressList, setAddressList] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classDrop = useStyl();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = localApi + `/transport/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setTransList(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimple = (event) => {
    const { value } = event.target;
    setSimpleSelect(value);
    const item = transList.find((item) => item.id === value);
    setAddressList(item.branch);
  };

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
    setBtn(true);
  };
  const handleConfirm = () => {
    fetch(
      localApi +
        `/branch/delete/byAdminAndTransport_BranchId/${userObj && userObj.id}/${
          cartId.id
        }`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={8} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Branch Address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                <GridItem xs={12} sm={8} md={8}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Transport Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelectImage",
                        id: "simple-select-image",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Transport Name...
                      </MenuItem>
                      {transList &&
                        transList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.transportName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <span style={{ color: "red" }}>{simpleSelectErr}</span> */}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {/* //cardIconTitle */}

        <GridContainer>
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "-100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            />
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
              cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            >
              Your imaginary file has been deleted.
            </SweetAlert>
          ) : null}
          {addressList &&
            addressList.map((a) => (
              <GridItem xs={12} sm={6} md={4} key={a.id}>
                <Card key={a.id}>
                  <CardHeader color="rose" icon></CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h3 className={classes.companyAlign}>
                          <small>{a.company}</small>
                        </h3>
                        <h4 className={classes.cardIconTitle}>
                          <small>Branch Name :- {a.branchName}</small>
                          <br />
                          <small>First Name :- {a.firstName}</small>
                          <br />
                          <small>Last Name :- {a.lastName}</small>
                          <br />
                          <small>Mobile :- {a.mobile}</small>
                          <br />
                          <small>WhatsApp :- {a.whatsApp}</small>
                          <br />
                          <small>Aadhar :- {a.aadharNumber}</small>
                          <br />
                          <small>Pan :- {a.panNumber}</small>
                          <br />
                          <small>GstIn :- {a.gstIn}</small>
                          <br />
                          <small>Alias Name :- {a.aliasName}</small>
                          <br />
                          <small>Plot No. :- {a.plotHouseNo}</small>
                          <br />
                          <small>Area :- {a.areaColony}</small>
                          <br />
                          <small>LandMark :- {a.landMark}</small>
                          <br />
                          <small>Road :- {a.road}</small>
                          <br />
                          <small>ZipCode :- {a.zipCode}</small>
                          <br />
                          <small>City :- {a.city}</small>
                          <br />
                          <small>State :- {a.state}</small>
                          <br />
                          <small>Country :- {a.country}</small>
                          <br />
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <Button
                        style={{ marginLeft: "10px" }}
                        color="rose"
                        onClick={() =>
                          history.push({
                            pathname: `/admin/updateBranch`,
                            state: a,
                          })
                        }
                        className={classes.updateProfileButton}
                      >
                        Edit Address
                      </Button>
                      {/* <Button
                        style={{ marginLeft: "10px" }}
                        color="rose"
                        onClick={() => handleRemove(a)}
                        className={classes.updateProfileButton}
                      >
                        Delete Address
                      </Button> */}
                      {btn === true ? (
                        <Button
                          style={{ marginLeft: "10px" }}
                          className={classes.updateProfileButton}
                        >
                          Delete Address
                        </Button>
                      ) : (
                        <Button
                          style={{ marginLeft: "10px" }}
                          color="rose"
                          onClick={() => handleRemove(a)}
                          className={classes.updateProfileButton}
                        >
                          Update
                        </Button>
                      )}
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridContainer>
    </div>
  );
}
