import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import GroupIcon from "@material-ui/icons/Group";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Badge from "components/Badge/Badge.js";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import { localApi } from "utils/imgApi";
import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useHoever = makeStyles(styHoever);
const useStyleSweet = makeStyles(styleSweet);

export default function UserList(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [cartId, setCartId] = React.useState();

  const classes = useStyles();
  const classe = useStyle();
  const classeHover = useHoever();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = localApi + `/register/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems2(getResult && getResult);

    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    // console.log(cartId);
    fetch(localApi + `/register/delete/byId/${cartId.id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GroupIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View User List</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        {submitteds ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title="Are you sure?"
            onConfirm={handleConfirm}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          />
        ) : null}

        {submittedConfirm ? (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Deleted!"
            onConfirm={() => hideAlerts()}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Your imaginary file has been deleted.
          </SweetAlert>
        ) : null}
        {items2 &&
          items2.map((Ele) => (
            <GridItem xs={12} sm={4} md={4} key={Ele.id}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">{Ele.type}</CardIcon>
                  {/* <h3 className={classes.cardIconTitle}>
                  <small>Select Design Images</small>
                </h3> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <span>
                        <strong>{Ele.firstname + " " + Ele.lastname}</strong>
                        <small
                          style={{
                            marginTop: "0px",
                            float: "right",
                            marginRight: "0px",
                            color: "red",
                          }}
                        >
                          <Tooltip
                            id="tooltip-top"
                            title="Remove"
                            placement="right"
                            classes={{ tooltip: classeHover.tooltip }}
                          >
                            <DeleteForeverIcon
                              size={10}
                              color="danger"
                              onClick={() => handleRemove(Ele)}
                              className={classeHover.underChartIcons}
                            />
                          </Tooltip>
                        </small>
                      </span>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                      <span>Company</span>
                      <br />
                      <sapn>Mobile</sapn>
                      <br />
                      <span>WhatsApp</span>
                      <br />
                      <span>City</span>
                      <br />
                      <span>Last Update</span>
                      <br />
                    </GridItem>
                    <GridItem xs={6} sm={8} md={8}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {Ele.company}
                      </span>
                      <br />
                      <ImMobile />
                      &nbsp;&nbsp;
                      <span>
                        <a href={`tel: ${Ele.mobile}`}>{Ele.mobile}</a>
                      </span>
                      <br />
                      <IoLogoWhatsapp color="green" />
                      &nbsp;&nbsp;
                      <span>
                        <a
                          href={`https://api.whatsapp.com/send/?phone=${Ele.whatsapp}&text=Hello&app_absent=0`}
                        >
                          {Ele.whatsapp}
                        </a>
                      </span>
                      <br />
                      <span>{Ele.city}</span>
                      <br />
                      <span>{Ele.endDate.slice(0, -19)}</span> &nbsp;{" "}
                      {/* <br /> */}
                      <span style={{ color: "#d81b60", fontSize: "12px" }}>
                        {Ele.lastDays}
                      </span>
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
