import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FaEdit } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Selects from "react-select";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/imgApi";
import { getStates, getCities } from "utils/util";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import avatar from "assets/img/faces/marc.jpg";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styleSweet);
const useStyCheck = makeStyles(styleCheck);

export default function UpdateClientProfile() {
  const [items, setItems] = React.useState([]);
  const [firstname, setFristname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [image, setImage] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("in");
  const [countryName, setCountryName] = React.useState("India");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [cityErr, setCityErr] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [countryErr, setCountryErr] = React.useState("");
  const [sname, setSname] = React.useState("");
  const [cname, setCname] = React.useState("");

  const [clientId, setClientId] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyl();
  const classCheck = useStyCheck();

  let adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api =
      localApi + `/register/list/byAdminId/${adminObj && adminObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems(getResult && getResult.filter((f) => f.type != "Crm"));
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleBoots = (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      // console.log(newSpli);
      const ids = items.find((f) => f.company === newSpli[0] || f.mobile === newSpli[1]);
      // console.log(ids);
      setClientId(ids.id ? ids.id : "");
      setFristname(ids.firstname ? ids.firstname : "");
      setLastname(ids.lastname ? ids.lastname : "");
      setEmail(ids.email ? ids.email : "");
      setActive(ids.smsMarketing ? ids.smsMarketing : "");
      setMobile(ids.mobile ? ids.mobile : "");
      setWhatsapp(ids.whatsapp ? ids.whatsapp : "");
      setCompany(ids.company ? ids.company : "");
      setCountry(ids.country ? ids.country : "");
      setCountryCode(ids.countryCode ? ids.countryCode : "");
      setStateSelect(ids.state ? ids.state : "")
      const sc = getStates(ids.countryCode ? ids.countryCode : "").filter((f) => f.name === ids.state)[0];
      setStateCode(sc.state_code);
      setCitySelect(ids.city ? ids.city : "")
      setSname(ids.state ? ids.state : "");
      setCname(ids.city ? ids.city : "");
    }
    setSimpleSelect(simpleSelect);
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
  };

  const SubmitProfile = (event) => {
    let errorCount = 0;
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Please Enter Email");
    }
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectErr("Please Select Client");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("State is Required");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("City is Required");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          whatsapp: whatsapp,
          company: company,
          smsMarketing: active,
          country: country,
          state: stateSelect,
          city: citySelect,
        }),
      };

      fetch(localApi + `/register/update/byId/${clientId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Successfully !!!");
            setSubmitted(true);
          }
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Profile</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Updated Client Profile</SweetAlert>
                ) : null}
                <GridContainer justify="center">
                  {" "}
                  <GridItem xs={12} sm={12} md={8}>
                    <label className={classe.selectLabel}>Select Company & Client Name</label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      required={true}
                      placeholder="Select Company & Client Name..."
                      value={simpleSelect}
                      name="simpleSelect"
                      onChange={handleBoots}
                      options={
                        items &&
                        items.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                          };
                        })
                      }
                    />
                    <span style={{ color: "red" }}>{simpleSelectErr}</span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {simpleSelect ? (
                    <>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          labelText="First Name"
                          id="firstname"
                          inputProps={{
                            placeholder: "First Name ...",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="firstname"
                          value={firstname}
                          onChange={(e) => {
                            setFristname(e.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          labelText="Last Name"
                          id="lastname"
                          inputProps={{
                            placeholder: "Last Name ...",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="lastname"
                          value={lastname}
                          onChange={(e) => {
                            setLastname(e.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          labelText="Email"
                          id="email"
                          inputProps={{
                            placeholder: "Email ...",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailErr("");
                          }}
                          required={true}
                          disabled={true}
                        />
                        <span style={{ color: "red" }}>{emailErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <PhoneInput
                          style={mystyle}
                          inputStyle={{
                            width: "95%",
                            border: "none",
                            borderBottom: "1px solid #040000",
                            borderRadius: "0",
                          }}
                          country={"in"}
                          name="mobile"
                          value={mobile}
                          onChange={(mobile) => {
                            let country_code = document.body.querySelector(
                              "div.flag"
                            )?.className;
                            country_code =
                              country_code && country_code.replace("flag", "");
                            setCountryCode(country_code.trim());
                            // console.log(country_code);
                            handleMobileNumber(mobile);
                            let country_name = document.body.querySelector(
                              "div.selected-flag"
                            )?.title;
                            setCountryName(country_name.slice(0, -6));
                          }}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <PhoneInput
                          style={mystyle}
                          inputStyle={{
                            width: "95%",
                            border: "none",
                            borderBottom: "1px solid #040000 ",
                            borderRadius: "0",
                          }}
                          country={"in"}
                          name="whatsapp"
                          value={whatsapp}
                          onChange={(whatsapp) => {
                            setWhatsapp(whatsapp);
                          }}
                          // required={true}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Company Name"
                          id="company"
                          inputProps={{
                            placeholder: "Company Name ...",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="company"
                          value={company}
                          onChange={(e) => {
                            setCompany(e.target.value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select State...{sname}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={stateSelect}
                            onChange={handleState}
                            required={true}
                            inputProps={{
                              name: "stateSelect",
                              id: "simple-select",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select State
                            </MenuItem>
                            {getStates(countryCode).map((item) => (
                              <MenuItem
                                key={item.name}
                                value={item.name}
                                onClick={() => {
                                  setStateCode(item?.state_code);
                                }}
                                classes={{
                                  root: classe.selectMenuItem,
                                  selected: classe.selectMenuItemSelected,
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>{stateNameErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select City...{cname}
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={citySelect}
                            onChange={handleCity}
                            required={true}
                            inputProps={{
                              name: "stateSelect",
                              id: "simple-select",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select City
                            </MenuItem>
                            {getCities(countryCode, stateCode).map((item) => (
                              <MenuItem
                                key={item.name}
                                value={item.name}
                                classes={{
                                  root: classe.selectMenuItem,
                                  selected: classe.selectMenuItemSelected,
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>{cityErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Country..."
                          id="country"
                          inputProps={{
                            placeholder: "Country...",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="country"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            setCountryErr("");
                          }}
                          required={true}
                          disabled={true}
                        />
                        <span style={{ color: "red" }}>{countryErr}</span>
                      </GridItem>
                      <GridItem xs={12} md={6}></GridItem>
                      <GridItem xs={12} md={12}>
                        <FormControlLabel
                          classes={{
                            root: classCheck.checkboxLabelControl,
                            label: classCheck.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              id="active"
                              value={active}
                              checked={
                                active & (active === true) ? active : active
                              }
                              onChange={(e) => {
                                setActive(e.target.checked);
                              }}
                              tabIndex={-1}
                              checkedIcon={
                                <Check className={classCheck.checkedIcon} />
                              }
                              icon={
                                <Check className={classCheck.uncheckedIcon} />
                              }
                              classes={{
                                checked: classCheck.checked,
                                root: classCheck.checkRoot,
                              }}
                            />
                          }
                          label={
                            <span style={{ color: "#9c27b0" }}>
                              I would like to recieve the latest news from the
                              Company by phone/whatsapp
                            </span>
                          }
                        />
                      </GridItem>
                    </>
                  ) : (
                    ""
                  )}
                </GridContainer>
                {simpleSelect ? (
                  // <Button
                  //   color="rose"
                  //   onClick={SubmitProfile}
                  //   className={classes.updateProfileButton}
                  // >
                  //   Update
                  // </Button>
                  btn === true ? (
                    <Button className={classes.updateProfileButton}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="rose"
                      onClick={SubmitProfile}
                      className={classes.updateProfileButton}
                    >
                      Update
                    </Button>
                  )
                ) : (
                  ""
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  src={
                    image === "/user_cropimg/null"
                      ? defaultAvatar
                      : image === "/user_cropimg/"
                      ? defaultAvatar
                      : image === null
                      ? defaultAvatar
                      : image
                      ? imgApi + "" + image
                      : defaultAvatar
                  }
                />
                {/* <img src={image === null ?
                  defaultAvatar
                  : image === "/user_cropimg/null" ?
                    defaultAvatar
                    : image === "/user_cropimg/" ?
                      defaultAvatar
                      : image ? image : defaultAvatar} alt="..." /> */}
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>
                {firstname + "  " + lastname}
              </h4>
              <p className={classes.description}>
                In this ever-changing society, the most powerful and enduring
                brands are built from the heart. They are real and sustainable.
                Their foundations are stronger because they are built with the
                strength of the human spirit. Don’t limit yourself. Many people
                limit themselves to what they think they can do. You can go as
                far as your mind lets you. What you believe, remember, you can
                achieve.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
