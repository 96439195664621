import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import Selects from "react-select";
import Switch from "@material-ui/core/Switch";
import Badge from "components/Badge/Badge.js";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useHoever = makeStyles(styHoever);

export default function ViewClientAddress(props) {
  const [items, setItems] = React.useState([]);
  const [addressList, setAddressList] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [cartId, setCartId] = React.useState();
  const [clientid, setClientid] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classeHover = useHoever();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUser() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${adminObj && adminObj.id}`;
    const results = await fetch(api);
    const getResults = await results.json();
    setItems(getResults && getResults.filter((f) => f.type != "Crm"));
    // console.log(getResults.user);
  }

  useEffect(() => {
    getUser();
  }, []);

  const handleBoots = async (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.mobile === newSpli[1]);
      // console.log(ids);
      const api = localApi + `/register/byMobile/${ids.mobile}`;
      const result = await fetch(api);
      const getResult = await result.json();
      // console.log(getResult);
      setClientid(getResult && getResult);
      const apis =
        localApi +
        `/regAddress/list/byAdminAndRegisterId/${adminObj && adminObj.id}/${
          getResult.id
        }`;
      const results = await fetch(apis);
      const getResults = await results.json();
      // console.log(getResults);
      setAddressList(getResults && getResults);
    }
    setSimpleSelect(simpleSelect);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    fetch(localApi + `/regAddress/delete/byId/${cartId.id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const handleActive = (a) => {
    if (checkedFullSize === false) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          country: "India",
        }),
      };

      fetch(
        localApi +
          `/regAddress/update/byAdminId/${adminObj && adminObj.id}/${
            clientid.id
          }/${a.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Your delivery Updated Succesfully");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select client delivery address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={8} md={8}>
                  <label className={classe.selectLabel}>Select Company & Client Name</label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Company & Client Name..."
                    value={simpleSelect}
                    name="simpleSelect"
                    onChange={handleBoots}
                    options={
                      items &&
                      items.map((c) => {
                        return {
                          label:
                            c && c.company ? c.company + "-" + c.mobile : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                          c && c.company ? c.company + "-" + c.mobile : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                  <span style={{ color: "red" }}>{simpleSelectErr}</span>
                </GridItem>{" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "-100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            />
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
              cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            >
              Your imaginary file has been deleted.
            </SweetAlert>
          ) : null}
          {addressList &&
            addressList.map((a) => (
              <GridItem xs={12} sm={12} md={4} key={a.id}>
                <Card>
                  <CardBody>
                    <p className={classes.cardDescription}>
                      {a && a.company
                        ? a.company
                        : a.firstName + " " + a.lastName}

                      <small style={{ float: "right", marginRight: "5px" }}>
                        <Tooltip
                          id="tooltip-top"
                          title="Deliver to this address"
                          placement="left"
                          classes={{ tooltip: classeHover.tooltip }}
                        >
                          <Switch
                            onChange={(e) => {
                              setCheckedFullSize(e.target.checked);
                              handleActive(a);
                            }}
                            key={a.id}
                            value={a.id}
                            checked={
                              checkedFullSize && checkedFullSize
                                ? checkedFullSize === a.id
                                : a.active === true
                            }
                            id={a.id}
                            name="checkedFullSize"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </Tooltip>
                      </small>
                      <small
                        style={{
                          marginTop: "0px",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Tooltip
                          id="tooltip-top"
                          title="Remove"
                          placement="right"
                          classes={{ tooltip: classeHover.tooltip }}
                        >
                          <Button
                            color="danger"
                            simple
                            justIcon
                            onClick={() => handleRemove(a)}
                            size="lg"
                            style={{ marginTop: "0px" }}
                          >
                            <DeleteForeverIcon
                              className={classeHover.underChartIcons}
                            />
                          </Button>
                        </Tooltip>
                      </small>
                    </p>
                    <p className={classes.cardDescription}>
                      {a.active === true ? (
                        <Badge color="success">{a.addressType}</Badge>
                      ) : (
                        a.addressType
                      )}
                    </p>
                    <p className={classes.cardDescription}>
                      {a.plotHouseNo + " " + a.areaColony}
                      <br />
                      {a.landMark + " " + a.road + " - " + a.zipCode}
                      <br />
                      {a.city + ", " + a.state + ", " + a.country}
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridContainer>
    </div>
  );
}
