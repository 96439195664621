import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import { localApi } from "utils/imgApi";
import axios from "axios";
import Badge from "components/Badge/Badge.js";
import { BiEdit } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { getStates, getCities } from "utils/util";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Selects from "react-select";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styl from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styl);
const useStyless = makeStyles(styRadio);
const useHoever = makeStyles(styHoever);

export default function UpdateShippingAddress() {
  const [updateAdd, setUpdateAdd] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [transList, setTransList] = React.useState([]);
  const [newAddress, setNewAddress] = React.useState(false);

  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [checkedTransport, setCheckedTransport] = React.useState(false);

  const [clientid, setClientid] = React.useState("");
  const [registerid, setRegisterid] = React.useState("");
  const [cartId, setCartId] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [whatsApp, setWhatApp] = React.useState("");
  const [whatsAppErr, setWhatAppErr] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [aadharNumberErr, setAadharNumberErr] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstIn, setGstIn] = React.useState("");
  const [gstInErr, setGstInErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [aliasName, setAliasName] = React.useState("");
  const [plotHouseNo, setPlotHouseNo] = React.useState("");
  const [areaColony, setAreaColony] = React.useState("");
  const [landMark, setLandMark] = React.useState("");
  const [road, setRoad] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeErr, setZipCodeErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [active, setActive] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState("in");
  const [stateSelect, setStateSelect] = React.useState("");
  const [stateSelectErr, setStateSelectErr] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [citySelectErr, setCitySelectErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");

  const [addressList, setAddressList] = React.useState([]);
  const [simpleSelectTransport, setSimpleSelectTransport] = React.useState("");

  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");

  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyle();
  const classDrop = useStyl();
  const classesRadio = useStyless();
  const history = useHistory();
  const classeHover = useHoever();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");

  async function getUserClient() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(api);
    const getResults = await results.json();
    setItems(getResults && getResults);
    // console.log(getResults);
  }

  useEffect(() => {
    getUserClient();
  }, []);

  async function getUsers() {
    const api = localApi + `/transport/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setTransList(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimpleTransport = (event) => {
    const { value } = event.target;
    setSimpleSelectTransport(value);
    const item = transList.find((item) => item.id === value);
    // console.log(item);
    setAddressList(item.branch);
  };

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateSelectErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCitySelectErr("");
  };

  const handleBoots = async (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.mobile === newSpli[1]);
      setClientid(ids && ids);
      const api = localApi + `/register/byMobile/${ids.mobile}`;
      const result = await fetch(api);
      const getResult = await result.json();
      // console.log(getResult);
      setRegisterid(getResult && getResult);
    }
    setSimpleSelect(simpleSelect);
    setSimpleSelectErr("");
  };

  const handleSimpleType = (event) => {
    const { value } = event.target;
    setSimpleSelectType(value);
    setSimpleSelectTypeErr("");

    fetch(
      localApi +
        `/regAddress/findByAddressTypeAndAdminAndClientAndRegisterId/${userObj.id}/${clientid.id}/${registerid.id}/${value}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setSimpleSelectTypeErr(result.message);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleGST = (e) => {
    const { value } = e.target;
    setGstIn(value);
    setGstInErr("");

    let regTest = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regTest.test(value)) {
      // errorCount++;
      setGstInErr("GST No. Not valid");
    }

    let gstVal = value;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/;
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[A-Z]{5}$/;
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/;
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[A-Z]{1}$/;
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9A-Z]{1}$/;
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^Z$/;
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9A-Z]{1}$/;
    if (gstVal.length != 15) {
      setGstInErr(
        "Length should be restricted to 15 digits and should not allow anything more or less"
      );
    } else if (!patternstatecode.test(statecode)) {
      setGstInErr("First two characters of GSTIN should be Number");
    } else if (!patternthreetoseven.test(threetoseven)) {
      setGstInErr(
        "Third to seventh characters of GSTIN should be Alphabets Capital Letter"
      );
    } else if (!patternseventoten.test(seventoten)) {
      setGstInErr("Eighth to Eleventh characters of GSTIN should be Number");
    } else if (!patternTwelveth.test(Twelveth)) {
      setGstInErr(
        "Twelveth character of GSTIN should be Alphabet Capital Letter"
      );
    } else if (!patternThirteen.test(Thirteen)) {
      setGstInErr(
        "Thirteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (!patternfourteen.test(fourteen)) {
      setGstInErr(
        "fourteen characters of GSTIN should be Alphabet Capital Letter"
      );
    } else if (!patternfifteen.test(fifteen)) {
      setGstInErr(
        "fifteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (statecode < 1 || statecode > 37) {
      setGstInErr("Invalid First Two Characters of GSTIN");
    }

    fetch(
      `https://sheet.gstincheck.co.in/check/028ec5a297d1ae03ba935ac49586e228/${value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "GSTIN  found.") {
          setAreaColony(res.data.pradr.adr);
          setStateSelect(res.data.pradr.addr.stcd);
          setCitySelect(res.data.pradr.addr.dst);
        } else if (res.message === "Invalid GSTIN Number") {
          setGstInErr("Invalid GSTIN Number");
        } else if (res.message === "Credit Not Available") {
          setGstInErr("Not Available");
        }
        // console.log(res);
      });
  };

  React.useEffect(() => {
    axios
      .get(
        localApi +
          `/regAddress/list/byAdminAndRegisterId/${
            userObj && userObj.id
          }/${client}`
      )
      .then((res) => {
        // console.log(res.data);
        setUpdateAdd(res && res.data);
      });
  }, []);

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    fetch(localApi + `/regAddress/delete/byId/${cartId.id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const handleActive = (a) => {
    if (checkedFullSize === false) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          country: "India",
        }),
      };

      fetch(
        localApi +
          `/regAddress/update/byAdminId/${userObj.id}/${client}/${a.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Your delivery Updated Succesfully");
            setSubmitted(true);
          }
        });
    }
  };

  const SubmitAddress = () => {
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Mobile Number");
    }
    if (whatsApp === "" || !whatsApp) {
      errorCount++;
      setWhatAppErr("Please Enter WhatsApp Number");
    }
    if (zipCode === "" || !zipCode) {
      errorCount++;
      setZipCodeErr("Please Enter Zip Code");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCitySelectErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateSelectErr("Please Select State Name");
    }
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectErr("Please Select Client");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Address Type");
    }
    if (simpleSelectType != "GSTIN ADDRESS") {
      if (simpleSelectType != "DELIVERY ADDRESS") {
        if (aadharNumber === "" || !aadharNumber) {
          errorCount++;
          setAadharNumberErr("Please Enter Aadhar Number");
        }
      }
    }
    if (simpleSelectType != "AADHAR ADDRESS") {
      if (gstIn === "" || !gstIn) {
        errorCount++;
        setGstInErr("Please Enter GST Number");
      }
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.id,
          registerId: registerid.id,
          clientId: clientid.id,
          addressType: simpleSelectType,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          whatsApp: whatsApp,
          aadharNumber:
            simpleSelectType == "GSTIN ADDRESS" ||
            simpleSelectType == "DELIVERY ADDRESS"
              ? null
              : aadharNumber,
          panNumber: panNumber ? panNumber : null,
          gstIn: simpleSelectType == "AADHAR ADDRESS" ? null : gstIn,
          company: company ? company : null,
          aliasName: aliasName ? aliasName : null,
          plotHouseNo: plotHouseNo ? plotHouseNo : null,
          areaColony: areaColony ? areaColony : null,
          landMark: landMark ? landMark : null,
          road: road ? road : null,
          zipCode: zipCode,
          city: citySelect,
          state: stateSelect,
          country: country,
          // active: active,
        }),
      };

      fetch(localApi + "/regAddress/add", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Already Exist") {
            alertTypeOps("danger");
            alertMsgOps("Already Exits !!!");
            setSubmitted(true);
          } else if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Added Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div
      style={{
        marginLeft: "35px",
        marginRight: "35px",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Shipping Address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                {submitteds ? (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "100px" }}
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                    cancelBtnCssClass={
                      classSweet.button + " " + classSweet.danger
                    }
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                  />
                ) : null}

                {submittedConfirm ? (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlerts()}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Your imaginary file has been deleted.
                  </SweetAlert>
                ) : null}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {newAddress === true ? (
                      <GridContainer>
                        <GridItem md={12} sm={12} xs={12}>
                          <Button
                            color="rose"
                            onClick={() => setNewAddress(false)}
                          >
                            Add New Address
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer>
                        <GridItem md={12} sm={12} xs={12}>
                          <Button
                            color="rose"
                            onClick={() => setNewAddress(true)}
                          >
                            Add New Address
                          </Button>
                        </GridItem>
                      </GridContainer>
                    )}
                  </GridItem>
                </GridContainer>
                {newAddress === true ? (
                  <>
                    <form>
                      {submitted ? (
                        <SweetAlert
                          type={alertType}
                          style={{ display: "block", marginTop: "-100px" }}
                          title={alertMsg}
                          onConfirm={() => hideAlert()}
                          confirmBtnCssClass={
                            classSweet.button + " " + classSweet.success
                          }
                        />
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <label className={classSweet.selectLabel}>
                            Select Client
                          </label>
                          <Selects
                            styles={customStyles}
                            style={select}
                            required={true}
                            placeholder="Select Client..."
                            value={simpleSelect}
                            name="simpleSelect"
                            onChange={handleBoots}
                            options={
                              items &&
                              items.map((c) => {
                                return {
                                  label:
                                    c.firstname +
                                    " " +
                                    c.lastname +
                                    "-" +
                                    c.mobile,
                                  value:
                                    c.firstname +
                                    " " +
                                    c.lastname +
                                    "-" +
                                    c.mobile,
                                };
                              })
                            }
                          />
                          <span style={{ color: "red" }}>
                            {simpleSelectErr}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={2}>
                          <FormControl
                            fullWidth
                            className={classDrop.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classDrop.selectLabel}
                            >
                              Select Address Type...
                            </InputLabel>

                            <Select
                              MenuProps={{
                                className: classDrop.selectMenu,
                              }}
                              classes={{
                                select: classDrop.select,
                              }}
                              value={simpleSelectType}
                              onChange={handleSimpleType}
                              inputProps={{
                                name: "stateSelect",
                                id: "simple-select",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classDrop.selectMenuItem,
                                }}
                              >
                                Select Address Type
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                                value="AADHAR ADDRESS"
                              >
                                AADHAR ADDRESS
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                                value="GSTIN ADDRESS"
                              >
                                GSTIN ADDRESS
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                                value="DELIVERY ADDRESS"
                              >
                                DELIVERY ADDRESS
                              </MenuItem>
                              <MenuItem
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                                value="OTHER ADDRESS"
                              >
                                OTHER ADDRESS
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <span style={{ color: "red" }}>
                            {simpleSelectTypeErr}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <label>Mobile Number</label>
                          <PhoneInput
                            style={mystyle}
                            inputStyle={{
                              width: "95%",
                              border: "none",
                              borderBottom: "1px solid #040000",
                              borderRadius: "0",
                            }}
                            country={"in"}
                            name="mobile"
                            value={mobile}
                            onChange={(mobile) => {
                              let country_code = document.body.querySelector(
                                "div.flag"
                              )?.className;
                              country_code =
                                country_code &&
                                country_code.replace("flag", "");
                              setCountryCode(country_code.trim());
                              // console.log(country_code);
                              handleMobileNumber(mobile);
                              let country_name = document.body.querySelector(
                                "div.selected-flag"
                              )?.title;
                              setCountry(country_name.slice(0, -6));
                            }}
                            // disabled={true}
                          />
                          <span style={{ color: "red", marginLeft: "12px" }}>
                            {mobileErr}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <label>WhatsApp Number</label>
                          <PhoneInput
                            style={mystyle}
                            inputStyle={{
                              width: "95%",
                              border: "none",
                              borderBottom: "1px solid #040000 ",
                              borderRadius: "0",
                            }}
                            country={"in"}
                            name="whatsApp"
                            value={whatsApp}
                            onChange={(whatsApp) => {
                              setWhatApp(whatsApp);
                              setWhatAppErr("")
                            }}
                            // required={true}
                          />
                          <span style={{ color: "red", marginLeft: "12px" }}>
                            {whatsAppErr}
                          </span>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="First Name"
                            id="firstName"
                            inputProps={{
                              placeholder: "First Name ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="firstName"
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setFirstNameErr("");
                            }}
                          />
                          <span style={{ color: "red", marginLeft: "12px" }}>
                            {firstNameErr}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Last Name"
                            id="lastName"
                            inputProps={{
                              placeholder: "Last Name ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="lastName"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              setLastNameErr("");
                            }}
                          />
                          <span style={{ color: "red", marginLeft: "12px" }}>
                            {lastNameErr}
                          </span>
                        </GridItem>
                        {simpleSelectType == "GSTIN ADDRESS" ||
                        simpleSelectType == "DELIVERY ADDRESS" ? (
                          ""
                        ) : (
                          <GridItem xs={12} sm={6} md={3}>
                            <CustomInput
                              labelText="Aadhar Number"
                              id="aadharNumber"
                              inputProps={{
                                placeholder: "Aadhar Number ...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="aadharNumber"
                              value={aadharNumber}
                              onChange={(e) => {
                                setAadharNumber(e.target.value);
                                setAadharNumberErr("");
                              }}
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {aadharNumberErr}
                            </span>
                          </GridItem>
                        )}
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Pan Number"
                            id="panNumber"
                            inputProps={{
                              placeholder: "Pan Number ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="panNumber"
                            value={panNumber}
                            onChange={(e) => setPanNumber(e.target.value)}
                          />
                        </GridItem>
                        {simpleSelectType == "AADHAR ADDRESS" ? (
                          ""
                        ) : (
                          <GridItem xs={12} sm={6} md={3}>
                            <CustomInput
                              labelText="GSTIN Number"
                              id="gstIn"
                              inputProps={{
                                placeholder: "GSTIN Number ...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="gstIn"
                              value={gstIn}
                              onChange={handleGST}
                              // onChange={(e) => setGstIn(e.target.value)}
                            />
                            <span style={{ color: "red" }}>{gstInErr}</span>
                          </GridItem>
                        )}
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Company"
                            id="company"
                            inputProps={{
                              placeholder: "Company ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Alias Name"
                            id="aliasName"
                            inputProps={{
                              placeholder: "Alias Name ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="aliasName"
                            value={aliasName}
                            onChange={(e) => setAliasName(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Plot House No."
                            id="plotHouseNo"
                            inputProps={{
                              placeholder: "Plot, House No., Building ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="plotHouseNo"
                            value={plotHouseNo}
                            onChange={(e) => setPlotHouseNo(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Area Colony"
                            id="areaColony"
                            inputProps={{
                              placeholder: "Area Colony Street Vilage ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="areaColony"
                            value={areaColony}
                            onChange={(e) => {
                              setAreaColony(e.target.value);
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Land Mark"
                            id="landMark"
                            inputProps={{
                              placeholder:
                                "LandMark e.g. Near In-Orbit Mall ...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="landMark"
                            value={landMark}
                            onChange={(e) => setLandMark(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Road"
                            id="road"
                            inputProps={{ placeholder: "Road ..." }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="road"
                            value={road}
                            onChange={(e) => setRoad(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Zip Code"
                            id="zipCode"
                            inputProps={{ placeholder: "Zip Code ..." }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="zipCode"
                            value={zipCode}
                            onChange={(e) => {
                              setZipCode(e.target.value);
                              setZipCodeErr("");
                            }}
                          />
                          <span style={{ color: "red", marginLeft: "12px" }}>
                            {zipCodeErr}
                          </span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <FormControl
                            fullWidth
                            className={classDrop.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classDrop.selectLabel}
                            >
                              Select State...
                            </InputLabel>

                            <Select
                              MenuProps={{
                                className: classDrop.selectMenu,
                              }}
                              classes={{
                                select: classDrop.select,
                              }}
                              value={stateSelect}
                              onChange={handleState}
                              required={true}
                              inputProps={{
                                name: "stateSelect",
                                id: "simple-select",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classDrop.selectMenuItem,
                                }}
                              >
                                Select State
                              </MenuItem>
                              {getStates(countryCode).map((item) => (
                                <MenuItem
                                  key={item.name}
                                  value={item.name}
                                  onClick={() => {
                                    setStateCode(item?.state_code);
                                  }}
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                    selected: classDrop.selectMenuItemSelected,
                                  }}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span style={{ color: "red" }}>{stateSelectErr}</span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <FormControl
                            fullWidth
                            className={classDrop.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classDrop.selectLabel}
                            >
                              Select City...
                            </InputLabel>

                            <Select
                              MenuProps={{
                                className: classDrop.selectMenu,
                              }}
                              classes={{
                                select: classDrop.select,
                              }}
                              value={citySelect}
                              onChange={handleCity}
                              required={true}
                              inputProps={{
                                name: "stateSelect",
                                id: "simple-select",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classDrop.selectMenuItem,
                                }}
                              >
                                Select City
                              </MenuItem>
                              {getCities(countryCode, stateCode).map((item) => (
                                <MenuItem
                                  key={item.name}
                                  value={item.name}
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                    selected: classDrop.selectMenuItemSelected,
                                  }}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <span style={{ color: "red" }}>{citySelectErr}</span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}>
                          <CustomInput
                            labelText="Country"
                            id="country"
                            inputProps={{ placeholder: "Country ..." }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled={true}
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3}></GridItem>
                      </GridContainer>
                      {/* <Button
                        // style={{ marginLeft: "110px" }}
                        color="rose"
                        onClick={SubmitAddress}
                        className={classes.updateProfileButton}
                      >
                        Add Address
                      </Button> */}
                      {btn === true ? (
                        <Button className={classes.updateProfileButton}>
                          Add Address
                        </Button>
                      ) : (
                        <Button
                          color="rose"
                          onClick={SubmitAddress}
                          className={classes.updateProfileButton}
                        >
                          Add Address
                        </Button>
                      )}
                    </form>
                  </>
                ) : (
                  ""
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Transport Name</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Transport Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelectTransport}
                      onChange={handleSimpleTransport}
                      inputProps={{
                        name: "simpleSelectTransport",
                        id: "simple-select-Transport",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Transport Name...
                      </MenuItem>
                      {transList &&
                        transList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.transportName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {addressList &&
          addressList.map((a) => (
            <GridItem xs={12} sm={6} md={3} key={a.id}>
              <Card key={a.id}>
                <CardBody>
                  <p className={classes.cardDescription}>
                    {a.company}
                    <small style={{ float: "right", marginRight: "5px" }}>
                      <Tooltip
                        id="tooltip-top"
                        title="Deliver to this address"
                        placement="left"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Switch
                          key={a.id}
                          value={a.id}
                          checked={
                            checkedTransport && checkedTransport.id
                              ? checkedTransport?.id === a?.id
                              : ""
                          }
                          onChange={(e) => {
                            setCheckedTransport(a);
                          }}
                          id={a.id}
                          name="checkedTranport"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      </Tooltip>
                    </small>
                  </p>
                  <p className={classes.cardDescription}>
                    <small>Branch Name :- {a.branchName}</small>
                    <br />
                    <small>City :- {a.city}</small>
                    <br />
                    <small>State :- {a.state}</small>
                    <br />
                    <small>Country :- {a.country}</small>
                    <br />
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      <GridContainer>
        {updateAdd &&
          updateAdd.map((a) => (
            <GridItem xs={12} sm={12} md={3} key={a.id}>
              <Card>
                <CardBody>
                  <p className={classes.cardDescription}>
                    {a && a.company
                      ? a.company
                      : a.firstName + " " + a.lastName}
                    <small style={{ float: "right", marginRight: "5px" }}>
                      <Tooltip
                        id="tooltip-top"
                        title="Deliver to this address"
                        placement="left"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Switch
                          onChange={(e) => {
                            setCheckedFullSize(e.target.checked);
                            handleActive(a);
                          }}
                          key={a.id}
                          value={a.id}
                          checked={
                            checkedFullSize && checkedFullSize
                              ? checkedFullSize === a.id
                              : a.active === true
                          }
                          id={a.id}
                          name="checkedFullSize"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      </Tooltip>
                    </small>
                  </p>
                  <p className={classes.cardDescription}>
                    {a.active === true ? (
                      <Badge color="success">{a.addressType}</Badge>
                    ) : (
                      a.addressType
                    )}
                  </p>
                  <p className={classes.cardDescription}>
                    {a.plotHouseNo + " " + a.areaColony}
                    <br />
                    {a.landMark + " " + a.road + " - " + a.zipCode}
                    <br />
                    {a.city + ", " + a.state + ", " + a.country}
                  </p>
                  <div
                    className={
                      classes.stats + " " + classes.justifyContentCenter
                    }
                  >
                    {a.active === true ? (
                      <Button
                        color="rose"
                        href={
                          a.active === true
                            ? `/cart-view?client=${a.clientId}&address=${a.id}&transport=${checkedTransport.id}&reg=${client}`
                            : ""
                        }
                      >
                        Proceed
                      </Button>
                    ) : (
                      <Button
                        color="default"
                        href={
                          a.active === true
                            ? `/cart-view?client=${a.clientId}&address=${a.id}&transport=${checkedTransport.id}&reg=${client}`
                            : ""
                        }
                      >
                        Proceed
                      </Button>
                    )}
                    <span
                      style={{
                        marginTop: "0px",
                        float: "right",
                        marginRight: "5px",
                      }}
                    >
                      <Tooltip
                        id="tooltip-top"
                        title="Remove"
                        placement="right"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Button
                          color="danger"
                          simple
                          justIcon
                          onClick={() => handleRemove(a)}
                          size="lg"
                          style={{ marginTop: "0px" }}
                        >
                          <DeleteForeverIcon
                            className={classeHover.underChartIcons}
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
