import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { localApi } from "utils/imgApi";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function DeletePacking() {
  const [items, setItems] = React.useState([]);
  const [dibbiName, setDibbiName] = React.useState("");
  const [hsncode, setHsncode] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [getId, setGetId] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleSimple = async (event) => {
    const { value } = event.target;
    setSimpleSelect(value);
    const api = await fetch(
      localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/${value}`
    );
    const result = await api.json();
    // console.log(result);
    setItems(result && result);
  };

  const handleSimple1 = async (event) => {
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect1(value);
    setDibbiName(item.pkgdesc);
    setHsncode(item.hsncode);
    setPrice(item.price);
    setGst(item.ligst);
    setGetId(item.id);
  };

  const SubmitDeletePacking = () => {
    setBtn(true);
    fetch(localApi + `/packingmaterial/delete/byId/${getId}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Delete Succesfully");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setDibbiName("");
    setHsncode("");
    setPrice("");
    setGst("");
    setGetId("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DeleteForeverIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Packing Material</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Packing Type...
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Packing Type...
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Crystal Dibbi"
                      >
                        Crystal Dibbi
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Plastic Dibbi"
                      >
                        Plastic Dibbi
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Polythene PP"
                      >
                        Polythene PP
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Material Name...
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Material Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.pkgdesc}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {simpleSelect1 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <h5 className={classes.cardIconTitle}>
                      <small>
                        Material Name :-{" "}
                        <span style={{ color: "black" }}>{dibbiName}</span>
                      </small>
                      <br />
                      <small>
                        HSN Code :-{" "}
                        <span style={{ color: "black" }}>{hsncode}</span>
                      </small>
                      <br />
                      <small>
                        Price :- <span style={{ color: "black" }}>{price}</span>
                      </small>
                      <br />
                      <small>
                        GST @18%:-{" "}
                        <span style={{ color: "black" }}>{gst}%</span>
                      </small>
                    </h5>
                  </GridItem>
                ) : (
                  ""
                )}
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitDeletePacking}
                className={classes.updateProfileButton}
              >
                Delete
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Delete</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitDeletePacking}
                  className={classes.updateProfileButton}
                >
                  Delete
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
