import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { localApi } from "utils/imgApi";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CornerRibbon from "react-corner-ribbon";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { AiOutlineFolderView } from "react-icons/ai";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useHoever = makeStyles(styHoever);
const useStyleSweet = makeStyles(styleSweet);

export default function ViewProduct(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [itemProd, setItemProd] = React.useState("");

  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");

  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const classes = useStyles();
  const classe = useStyl();
  const classeHover = useHoever();
  const classSweet = useStyleSweet();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");

  const { state } = useLocation();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  useEffect(() => {
    let newSpli = state && state ? state.split(",") : "";
    // console.log(newSpli);
    setSimpleSelect(newSpli && newSpli[0]);
    setSimpleSelect1(newSpli && newSpli[1]);
    axios
      .get(
        localApi +
          `/parent/list/byAdminIdAndCategoryId/${userObj.id}/${newSpli && newSpli[0]
          }`
      )
      .then((res) => setItems1(res.data));

      axios
      .get(
        localApi +
          `/product/list/byAdminAndCatAndParentId/${
            userObj && userObj.id
          }/${newSpli && newSpli[0]}/${newSpli && newSpli[1]}`
      )
      .then((res) => setItemProd(res.data));
  }, []);

  async function getUsers() {
    const api = localApi + `/category/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimple = (event) => {
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    // console.log(value);
    setSimpleSelect(value);
    setItems1(item.parent);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    // console.log(value);
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    // setCatSize(itemm);
    const api =
      localApi +
      `/product/list/byAdminAndCatAndParentId/${
        userObj && userObj.id
      }/${simpleSelect}/${itemm.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItemProd(getResult && getResult);
  };

  const handleDeleteDie = (prod) => {
    setSubmitteds(true);
    setCartId(prod);
  };

  const handleConfirm = () => {
    fetch(
      localApi +
        `/product/delete/byAdminAndProductId/${userObj && userObj.id}/${
          cartId.id
        }`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer
        justify="center"
        alignItems="center"
        style={{ marginBottom: "5%" }}
      >
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineFolderView />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                {submitteds ? (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "100px" }}
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                    cancelBtnCssClass={
                      classSweet.button + " " + classSweet.danger
                    }
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                  />
                ) : null}
                {submittedConfirm ? (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlerts()}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Your imaginary product has been deleted.
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <InfiniteScroll
        dataLength={itemProd.length}
        next={fetchData}
        hasMore={true}
      > */}
      <GridContainer>
        {itemProd &&
          itemProd.map((prod) => (
            <GridItem xs={12} sm={6} md={3} key={prod.id}>
              <Card product className={classeHover.cardHover}>
                <CardHeader image className={classeHover.cardHeaderHover}>
                <CornerRibbon
                          position={prod.active === true ? "Live" : ""} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="black" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        > {prod.active === true ? "Live" : ""}
                        </CornerRibbon>
                  {/* {prod.productImage.map(i => (
                      <img src={i.cropImageUrl + "?id=" + i.imgId} alt="..." />
                    ))} */}
                  <img
                    src={
                      prod.productImage[0].cropImageUrl +
                      "?id=" +
                      prod.productImage[0].imgId
                    }
                    alt="..."
                  />
                </CardHeader>
                <CardBody>
                  <div className={classeHover.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() =>
                          history.push({
                            // pathname: prod.active === false ? `/admin/updateProduct` : `/admin/onlineProductUpdate`,
                            pathname: `/admin/updateProduct`,
                            state: prod,
                          })
                        }
                      >
                        <Edit className={classeHover.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Remove"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={() => {
                          handleDeleteDie(prod);
                        }}
                      >
                        <DeleteForeverIcon
                          className={classeHover.underChartIcons}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  <h5
                    className={classeHover.cardProductTitle}
                    style={{ color: "#d81b60" }}
                  >
                    {/* {prod.category.title +
                        " " +
                        prod.parent.title}{" "}
                      <br /> {prod.itemcode}- */}
                    Product Id {prod.id}
                  </h5>
                  {/* <h6
                      className={classeHover.cardProductTitle}
                      style={{ fontWeight: "bold" }}
                    >
                      {prod.prodDescription}
                    </h6> */}
                  <p className={classeHover.cardProductDesciprion}>
                    {(prod.readyGram * prod.productSets.pcs).toFixed(4)} Avg
                    Gram Of {prod.productSets.pcs} set
                    {/* {prod.box.boxDesc}{" "}
                      {prod.ctndescription} */}
                    <br />
                    <span style={{ color: "green" }}>
                      Making Charge:- {prod.makingCharge.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "black" }}>
                      Cash Price:- {prod.cashPrice.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "#FFA500" }}>
                      Tax All:- {prod.taxAll.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      Tax Include:- {prod.taxInclude.toFixed(2)}
                    </span>
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
      {/* </InfiniteScroll> */}
    </div>
  );
}

ViewProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
