import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import classNames from "classnames";
import Select from "react-select";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { localApi } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Table from "components/Table/Tables.js";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ReceiptIcon from "@material-ui/icons/Receipt";

// material-ui icons
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Instruction from "components/Instruction/Instruction.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);
const useHoever = makeStyles(styHoever);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function OrdersCancel() {
  const [ordersList, setOrdersList] = React.useState([]);

  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandState, setExpandState] = React.useState({});

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();
  const classeHover = useHoever();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getOrders() {
    var tempData = {};
    const api = localApi + `/cancel/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // setOrdersList(getResult && getResult);
    // console.log(getResult);

    const orId = getResult.map((o) => {
      return o.ordersId;
    });
    // console.log(orId);

    Promise.all(
      orId.map(async (ordersId) => {
        const response = await fetch(
          localApi +
            `/payment/byAdminAndOrdersAndClientId/${
              userObj && userObj.id
            }/${ordersId}`
        );
        const data = await response.json();
        tempData = { ...tempData, [ordersId]: data };
        //   console.log(data);
      })
    ).then(() => {
      // console.log(tempData);
      const tempItems2 = getResult.map((i) => {
        return { ...i, u: tempData[i.ordersId] || 0 };
      });
      //   console.log(tempItems2);
      setOrdersList(tempItems2);
    });
  }

  useEffect(() => {
    getOrders();
  }, []);

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);
    // console.log(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);
    setExpandedRows(newExpandedRows);
  };

  return (
    <div className={classes.productPage}>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={12}>
                {" "}
                <h1 className={classes.OrderlistDescription}>
                  Order Cancel List
                </h1>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.mrAuto + " " + classes.mlAuto}
              >
                <Table
                  tableHead={[
                    <span className={classes.tableHead} key="sr">
                      Si. No.
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="des"
                    >
                      Name
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="ctn"
                    >
                      Invoice Number
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="ctn"
                    >
                      Date Of Invoice
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="qty"
                    >
                      Amount
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="adv"
                    >
                      Advance
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="tbx"
                    >
                      Total Box
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="tctn"
                    >
                      Total Carton
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="qty"
                    >
                      More
                    </span>,
                    <span
                      className={classes.tableHead + " " + classes.headRight}
                      key="view"
                    >
                      View
                    </span>,
                  ]}
                  tableData={
                    ordersList &&
                    ordersList.map((c, index) => [
                      [
                        <span className={classes.OrderlistDescription}>
                          {index + 1}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          {c.company
                            ? c.company
                            : c.firstName + " " + c.lastName}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          {c.invoiceNumber}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          {c.dateOfInvoice}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          ₹ {Math.round(c.amount) + "/-"}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          ₹ {Math.round(c.advance) + "/-"}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          {c.totalBox}
                        </span>,
                        <span
                          className={
                            classes.headRight + " " + classes.listNumber
                          }
                        >
                          {c.totalCarton}
                        </span>,
                        <span>
                          <Button
                            link
                            className={
                              classes.headRight + " " + classes.actionButton
                            }
                            onClick={(event) => handleEpandRow(event, c.id)}
                          >
                            {expandState[c.id] ? (
                              <ArrowUpwardIcon style={{ color: "red" }} />
                            ) : (
                              <ArrowDownwardIcon style={{ color: "red" }} />
                            )}
                          </Button>
                        </span>,
                        <span>
                          <Button color="primary" simple justIcon size="md" onClick={() =>
                          history.push({
                            pathname:
                            c.multiCartId != null
                                ? `/view-cancel-cart`
                                : "",
                            state: c.multiCartId,
                          })
                        }>
                            <VisibilityIcon />
                          </Button>
                        </span>,
                      ],
                      [
                        expandedRows.includes(c.id) ? (
                          <span>
                            {c.u.map((p, index) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                                key={p.id}
                              >
                                {index + 1}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span className={classes.headRight}>
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                                key={p.id}
                              >
                                {p.id}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        "",
                        expandedRows.includes(c.id) ? (
                          <span className={classes.headRight}>
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                                key={p.id}
                              >
                                {p.createDate.slice(0, -19)}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        // "",
                        "",
                        expandedRows.includes(c.id) ? (
                          <span className={classes.headRight}>
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                                key={p.id}
                              >
                                {p.received}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        "",
                        "",
                        "",
                        "",
                      ],
                    ])
                  }
                />
              </GridItem>
            </GridContainer>
          </Card>
        </div>
      </div>
    </div>
  );
}
