import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import classNames from "classnames";
import Select from "react-select";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { localApi } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Tables from "components/Table/Table.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ReceiptIcon from "@material-ui/icons/Receipt";

// material-ui icons
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Tables.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Instruction from "components/Instruction/Instruction.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);
const useHoever = makeStyles(styHoever);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ViewCart() {
  const [addCart, setAddCart] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [clientId, setClientId] = React.useState("");
  const [clientid, setClientid] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [noticeModal, setNoticeModal] = React.useState(false);
  const [ordersId, setOrdersId] = React.useState("");

  const [orderId, setOrderId] = React.useState("");
  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const [Date, setDate] = React.useState("");
  const [reverseCharge, setReverseCharge] = React.useState("");
  const [grRrNumber, setGrRrNumber] = React.useState("");
  const [stationName, setStation] = React.useState("");
  const [eWayBillNumber, setEWayBillNumber] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();
  const classeHover = useHoever();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getClient() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setClient(getResult && getResult.filter((f) => f.type != "Crm"));
    // console.log(getResult.user);
  }

  useEffect(() => {
    getClient();
  }, []);

  const handleDatePicker = (Date) => {
    setDate(
      Date._d.getFullYear() +
        "-" +
        (Date._d.getMonth() + 1) +
        "-" +
        Date._d.getDate()
    );
  };

  const handleBoots = (bootselect) => {
    if (bootselect.value) {
      let newCut = bootselect.value.split("-");
      const ids = client.find((f) => f.mobile == newCut[1]);
      setClientid(ids && ids);
      // console.log(ids);
      axios
        .get(
          localApi +
            `/orders/list/byAdminAndClientId/${userObj && userObj.id}/${ids.id}`
        )
        .then((res) => {
          //   console.log(res.data);
          const ress = res.data.filter((f) => f.cancelOrders != true);
          if (ress.length === 0) {
            setSubmitted(true);
            alertMsgOps("Data Not Found");
          } else {
            setAddCart(ress && ress);
          }
        });
    }
    setClientId(bootselect);
  };

  const handleOrderUpdate = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        dateOfInvoice: Date,
        invoiceNumber: invoiceNumber,
        reverseCharge: reverseCharge,
        grRrNumber: grRrNumber,
        stationName: stationName,
        eWayBillNumber: eWayBillNumber,
      }),
    };

    fetch(
      localApi +
        `/orders/update/byAdminAndOrderId/${userObj && userObj.id}/${
          orderId.id
        }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message === "Data Updated Successfully !!!") {
          window.location.reload(false);
        }
      });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleRemove = (c) => {
    setSubmitteds(true);
    setOrdersId(c);
  };

  const handleConfirm = () => {
    const cartId = ordersId.ordersCart.map((a) => {
      return a.cartId;
    });

    // console.log(cartId);
    // console.log(ordersId);

    const multiCartId = ordersId.ordersCart.map((c) => {
      return c.cartId;
    });

    // console.log(multiCartId);

    if (ordersId.payment.length == 0) {
      fetch(
        localApi +
          `/orders/delete/byAdminAndOrdersId/${userObj.id}/${ordersId.id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.message === "Deleted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Deleted Succesfully !!!");
            setSubmittedConfirm(true);

            cartId.forEach((x) =>
              axios
                .put(
                  localApi +
                    `/cart/update/byAdminAndCartIdAndFalse/${userObj.id}/${x}`
                )
                .then((res) => {
                  console.log(res.data);
                })
            );
          }
        });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstName: ordersId.firstName,
          lastName: ordersId.lastName,
          company: ordersId.company,
          invoiceNumber: ordersId.invoiceNumber,
          dateOfInvoice: ordersId.dateOfInvoice,
          ordersId: ordersId.id,
          multiCartId: multiCartId,
          adminId: userObj.id,
          clientId: ordersId.clientId,
          amount: ordersId.amount,
          advance: ordersId.advance,
          totalBox: ordersId.totalBox,
          totalCarton: ordersId.totalCarton,
        }),
      };
      fetch(localApi + `/cancel/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            // alertTypeOps("success");
            // alertMsgOps("Succesfully !!!");
            // setSubmitted(true);
            axios
              .put(
                localApi +
                  `/orders/update/byAdminAndOrderIdForCancelOrders/${userObj.id}/${ordersId.id}`
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Data Updated Successfully !!!") {
                  alertTypeOps("success");
                  alertMsgOps("Succesfully !!!");
                  setSubmittedConfirm(true);
                }
              });
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        {/* <Card>
                    <CardBody> */}
        <GridContainer
          justify="center"
          alignItems="center"
          className={classes.selects}
        >
          <GridItem xs={12} sm={6} md={4} style={{ marginBottom: "3%" }}>
            <label>Select Company & Client Name</label>
            <Select
              styles={customStyles}
              className={classes.select}
              placeholder="Select Company & Client Name..."
              value={clientId}
              onChange={handleBoots}
              options={client.map((c) => {
                return {
                  label:
                    c && c.company
                      ? c.company + "-" + c.mobile
                      : c.firstname + " " + c.lastname + "-" + c.mobile,
                  value:
                    c && c.company
                      ? c.company + "-" + c.mobile
                      : c.firstname + " " + c.lastname + "-" + c.mobile,
                };
              })}
            />
          </GridItem>
        </GridContainer>

        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
        {submitteds ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title="Are you sure?"
            onConfirm={handleConfirm}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
            cancelBtnCssClass={classeAlert.button + " " + classeAlert.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          />
        ) : null}

        {submittedConfirm ? (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Deleted!"
            onConfirm={() => hideAlerts()}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          >
            Your item has been deleted.
          </SweetAlert>
        ) : null}
      </GridContainer>

      {clientId ? (
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Card>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={12} md={12}>
                  {" "}
                  <h1 className={classes.OrderlistDescription}>My Order</h1>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.mrAuto + " " + classes.mlAuto}
                >
                  <Tables
                    tableHead={[
                      <span className={classes.OrderlistDescription}>
                        SI.No
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Date
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Units
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Amount
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Balance
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Status
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Quotation
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Invoice
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Payment
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        E-Way
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        Delete
                      </span>,
                    ]}
                    tableData={addCart.map((c, index) => [
                      <span className={classes.OrderlistDescription}>
                        {index + 1}
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        {c.createDate.slice(0, -19)}
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        {c.totalBox}
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        ₹ {Math.round(c.amount) + "/-"}
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        ₹ {Math.round(c.balance) + "/-"}
                      </span>,
                      <span className={classes.OrderlistDescription}>
                        {(
                          (c.payment.reduce((a, b) => a + b.received, 0) /
                            c.amount) *
                          100
                        ).toFixed(2)}
                        %
                      </span>,
                      <Button
                        color="primary"
                        simple
                        justIcon
                        size="md"
                        href={`/order-view-quotation?client=${c.clientId}&order=${c.id}`}
                      >
                        <VisibilityIcon />
                      </Button>,
                      <Button
                        color="primary"
                        simple
                        justIcon
                        size="md"
                        href={`/order-view?client=${c.clientId}&order=${c.id}`}
                      >
                        <VisibilityIcon />
                      </Button>,
                      <Button
                        color="primary"
                        simple
                        justIcon
                        size="md"
                        href={`/payment-recieved?client=${c.clientId}&order=${c.id}`}
                      >
                        <CreditCardIcon />
                      </Button>,
                      <Button
                        color="primary"
                        simple
                        justIcon
                        size="md"
                        onClick={() => {
                          setNoticeModal(true);
                          setOrderId(c);
                          // console.log(c);
                          setInvoiceNumber(c.invoiceNumber);
                          setDate(c.dateOfInvoice);
                          setReverseCharge(c.reverseCharge);
                          setGrRrNumber(c.grRrNumber);
                          setStation(c.stationName);
                          setEWayBillNumber(c.eWayBillNumber);
                        }}
                      >
                        <ReceiptIcon />
                      </Button>,
                      // <span style={{ float: "left", marginLeft: "0px" }}>
                      <Tooltip
                        id="tooltip-top"
                        title="Remove"
                        placement="right"
                        classes={{ tooltip: classeHover.tooltip }}
                      >
                        <Button
                          color="danger"
                          simple
                          justIcon
                          onClick={() => handleRemove(c)}
                          size="md"
                          style={{ marginTop: "0px" }}
                        >
                          <DeleteForeverIcon
                            className={classeHover.underChartIcons}
                          />
                        </Button>
                      </Tooltip>,
                      // </span>
                    ])}
                  />
                  <br />
                </GridItem>
              </GridContainer>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}

      <GridContainer>
        {/* {addCart &&
          addCart.map((c) => (
            <GridItem xs={12} sm={12} md={6} key={c.id}>
              <Card>
                <CardHeader color="rose" icon></CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4 className={classes.cardTitle}>
                        <span>
                          {clientid && clientid.company
                            ? clientid.company
                            : clientid &&
                              clientid.firstname + " " + clientid &&
                              clientid.lastname}
                        </span>
                        <span style={{ float: "right", marginLeft: "0px" }}>
                          <Tooltip
                            id="tooltip-top"
                            title="Remove"
                            placement="right"
                            classes={{ tooltip: classeHover.tooltip }}
                          >
                            <Button
                              color="danger"
                              simple
                              justIcon
                              onClick={() => handleRemove(c)}
                              size="lg"
                              style={{ marginTop: "0px" }}
                            >
                              <DeleteForeverIcon
                                className={classeHover.underChartIcons}
                              />
                            </Button>
                          </Tooltip>
                        </span>
                      </h4>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            Order ID:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Date:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Boxs/Doze:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Bill Amount:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Balance Amount:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            status:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Payment Status:{" "}
                          </span>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            {c.id}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.createDate.slice(0, -19)}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.totalBox + " - " + "Units"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            ₹ {Math.round(c.amount) + "/-"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            ₹ {Math.round(c.balance) + "/-"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.status === true ? 1 : 0}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {(
                              (c.payment.reduce((a, b) => a + b.received, 0) /
                                c.amount) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                          <CustomLinearProgress
                            variant="determinate"
                            key={c.id}
                            color="rose"
                            value={
                              (c.payment.reduce((a, b) => a + b.received, 0) /
                                c.amount) *
                              100
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="rose"
                    round
                    href={`/order-view?client=${c.clientId}&order=${c.id}`}
                  >
                    Orders Details
                  </Button>{" "}
                  <Button
                    color="rose"
                    round
                    href={`/payment-recieved?client=${c.clientId}&order=${c.id}`}
                  >
                    Payment & Details
                  </Button>
                  <Button
                    color="rose"
                    round
                    onClick={() => {
                      setNoticeModal(true);
                      setOrderId(c);
                      // console.log(c);
                      setInvoiceNumber(c.invoiceNumber);
                      setDate(c.dateOfInvoice);
                      setReverseCharge(c.reverseCharge);
                      setGrRrNumber(c.grRrNumber);
                      setStation(c.stationName);
                      setEWayBillNumber(c.eWayBillNumber);
                    }}
                  >
                    Invoice
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          ))} */}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classeDailog.center}>
            <Dialog
              classes={{
                root: classeDailog.center + " " + classeDailog.modalRoot,
                paper: classeDailog.modal,
              }}
              open={noticeModal}
              TransitionComponent={Transition}
              keepMounted
              // onClose={() => setNoticeModal(false)}
              aria-labelledby="notice-modal-slide-title"
              aria-describedby="notice-modal-slide-description"
            >
              <DialogTitle
                id="notice-modal-slide-title"
                disableTypography
                className={classeDailog.modalHeader}
              >
                <Button
                  justIcon
                  className={classeDailog.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => setNoticeModal(false)}
                >
                  <Close className={classeDailog.modalClose} />
                </Button>
                <h4 className={classeDailog.modalTitle}>E-Way</h4>
              </DialogTitle>
              <DialogContent
                id="notice-modal-slide-description"
                className={classeDailog.modalBody}
              >
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel className={classe.label}>
                      Date of Invoice
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        onChange={(Date) => handleDatePicker(Date)}
                        name="Date"
                        value={Date}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Invoice Number"
                      formControlProps={{
                        fullWidth: true,
                        className: classeDailog.customFormControlClasses,
                      }}
                      inputProps={{
                        placeholder: "Ex. IB/307/22-23",
                      }}
                      id="invoiceNumber"
                      name="invoiceNumber"
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Reverse Charge"
                      formControlProps={{
                        fullWidth: true,
                        className: classeDailog.customFormControlClasses,
                      }}
                      inputProps={{
                        placeholder: "Ex.Yes or No",
                      }}
                      id="reverseCharge"
                      name="reverseCharge"
                      value={reverseCharge}
                      onChange={(e) => setReverseCharge(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="GR RR Number"
                      formControlProps={{
                        fullWidth: true,
                        className: classeDailog.customFormControlClasses,
                      }}
                      inputProps={{
                        placeholder: "Ex. 1234",
                      }}
                      id="grRrNumber"
                      name="grRrNumber"
                      value={grRrNumber}
                      onChange={(e) => setGrRrNumber(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Station Name"
                      formControlProps={{
                        fullWidth: true,
                        className: classeDailog.customFormControlClasses,
                      }}
                      inputProps={{
                        placeholder: "Ex.Kolkata",
                      }}
                      id="stationName"
                      name="stationName"
                      value={stationName}
                      onChange={(e) => setStation(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="E-Way Bill Number"
                      formControlProps={{
                        fullWidth: true,
                        className: classeDailog.customFormControlClasses,
                      }}
                      inputProps={{
                        placeholder: "Ex.123412341234",
                      }}
                      id="eWayBillNumber"
                      name="eWayBillNumber"
                      value={eWayBillNumber}
                      onChange={(e) => setEWayBillNumber(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions
                className={
                  classeDailog.modalFooter +
                  " " +
                  classeDailog.modalFooterCenter
                }
              >
                <Button color="rose" round onClick={() => handleOrderUpdate()}>
                  update
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </div>
  );
}
