import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import Selects from "react-select";
import Switch from "@material-ui/core/Switch";
import Badge from "components/Badge/Badge.js";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useHoever = makeStyles(styHoever);

export default function ViewClientAddress(props) {
  const [items, setItems] = React.useState([]);
  const [messageList, setMessageList] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [cartId, setCartId] = React.useState();
  const [clientid, setClientid] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classeHover = useHoever();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUser() {
    const api =
      localApi + `/contactus/list/byAdminId/${adminObj && adminObj.id}`;
    const results = await fetch(api);
    const getResults = await results.json();
    setItems(getResults && getResults);
    // console.log(getResults.user);
  }

  useEffect(() => {
    getUser();
  }, []);

  const handleBoots = async (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.mobile === newSpli[1]);
      //   console.log(ids);
      const apis =
        localApi +
        `/contactus/list/byAdminIdAndMobile/${adminObj && adminObj.id}/${
          ids.mobile
        }`;
      const results = await fetch(apis);
      const getResults = await results.json();
      //   console.log(getResults);
      setMessageList(getResults && getResults);
    }
    setSimpleSelect(simpleSelect);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    fetch(localApi + `/contactus/delete/byContactUsId/${cartId.id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Contact Message</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={8} md={8}>
                  <label className={classe.selectLabel}>
                    Select Client Number
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Client Number..."
                    value={simpleSelect}
                    name="simpleSelect"
                    onChange={handleBoots}
                    options={
                      items &&
                      items.map((c) => {
                        return {
                          label: c && c.firstName + "-" + c.mobile,
                          value: c && c.firstName + "-" + c.mobile,
                        };
                      })
                    }
                  />
                  <span style={{ color: "red" }}>{simpleSelectErr}</span>
                </GridItem>{" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "-100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            />
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
              cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            >
              Your imaginary file has been deleted.
            </SweetAlert>
          ) : null}
          {messageList &&
            messageList.map((a) => (
              <GridItem xs={12} sm={4} md={4} key={a.id}>
                <Card>
                  <CardHeader color="rose" icon></CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.cardDescription}>
                          {a.firstName}
                          <small
                            style={{
                              float: "right",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip
                              id="tooltip-top"
                              title="Remove"
                              placement="bottom"
                              classes={{ tooltip: classeHover.tooltip }}
                            >
                              <Button
                                color="danger"
                                simple
                                justIcon
                                onClick={() => {
                                  handleRemove(a);
                                }}
                              >
                                <DeleteForeverIcon
                                  className={classeHover.underChartIcons}
                                />
                              </Button>
                            </Tooltip>
                          </small>
                        </p>
                        <p className={classes.cardDescription}>
                          <Badge color="rose">{a.websiteName}</Badge>
                          <br />
                          {a.mobile}
                          <br />
                          {a.email}
                          <br />
                          {a.message}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridContainer>
    </div>
  );
}
