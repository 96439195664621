import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

// material-ui icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
// import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function CartPending() {
  const [addCart, setAddCart] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // async function getCartPending() {
  //   var tempData = {};
  //   const api =
  //     localApi +
  //     `/cart/pendingCartShort/byAdminIdAndGroupClientAndAcitveZero/${userObj && userObj.id}?active=0`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   const cartIdCount = getResult && getResult.map((c) => {
  //     return c.cId;
  //   });

  //   Promise.all(
  //     cartIdCount.map(async (cId) => {
  //       const usedApi = await fetch(
  //         localApi +
  //           `/cart/pendingCart/byAdminAndClientIdAndAcitveAndCount/${userObj.id}/${cId}?active=0`
  //       );
  //       const data = await usedApi.json();
  //       tempData = { ...tempData, [cId]: data };
  //     })
  //   ).then(() => {
  //     const tempItems2 = getResult.map((i) => {
  //       return { ...i, u: tempData[i.cId] || 0 };
  //     });
  //     // console.log(tempItems2);
  //     if (tempItems2.length === 0) {
  //       setSubmitted(true);
  //       alertMsgOps("Data Not Found");
  //     } else {
  //       setAddCart(tempItems2 && tempItems2);
  //     }
  //   });
  // }

  async function getCartPending() {
    var tempData = {};
    const api = localApi + `/cart/listShort/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const cartIdActive =
      getResult &&
      getResult.filter((c) => {
        return c.active === false;
      });

    // console.log(cartIdActive);

    const grouped = [];
    cartIdActive.forEach((it) => {
      const existingClient = grouped.map((it) => it.cId);

      if (existingClient.includes(it.cId)) {
        const existingClient = grouped.find((ex) => ex.cId === it.cId);
        existingClient.aId += it.aId;
        // existingClient.rId += it.rId;
      } else {
        grouped.push({
          ...it,
          cId: it.cId,
        });
      }
    });

    // console.log(grouped);

    const z = cartIdActive.reduce(
      (m, x) => ({ ...m, [x.cId]: (m[x.cId] || 0) + 1 }),
      {}
    );
    // console.log(z);

    const tempItems2 = grouped.map((i) => {
      return { ...i, u: z[i.cId] || 0 };
    });
    // console.log(tempItems2);

    if (tempItems2.length === 0) {
      setSubmitted(true);
      alertMsgOps("Data Not Found");
    } else {
      setAddCart(tempItems2 && tempItems2);
    }
  }

  useEffect(() => {
    getCartPending();
  }, []);

  const hideAlert = () => {
    setSubmitted(false);
    history.push("/admin/dashboard");
    window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Icon>info_outline</Icon>
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Cart Pending</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
      </GridContainer>

      <GridContainer>
        {addCart &&
          addCart.map((c) => (
            <GridItem xs={12} sm={6} md={4} key={c.id}>
              <Card>
                <CardHeader color="rose" icon></CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4 className={classes.cardTitle}>
                        {c && c.company
                          ? c.company
                          : c && c.firstName + " " + c.lastName}
                      </h4>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            Cart List:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Create Date:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Last Seen:{" "}
                          </span>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            {c.u}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.createDate.slice(0, -19)}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.lastDays}
                          </span>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      color="rose"
                      round
                      onClick={() =>
                        history.push({
                          pathname: `/admin/viewPendingCart`,
                          state: c,
                        })
                      }
                    >
                      View Pending Cart
                    </Button>
                    <Button
                      color="rose"
                      round
                      onClick={() =>
                        history.push({
                          pathname: `/admin/updatePendingCart`,
                          state: c,
                        })
                      }
                    >
                      Edit Cart
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
