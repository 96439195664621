import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function UpdateLabourRate(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [labourDetails, setLabourDetails] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [usedId, setusedId] = React.useState("");
  const classes = useStyles();
  const classe = useStyle();
  const [btn, setBtn] = React.useState(false);

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = headApi + `/category/catbyuser/1`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems(getResult.CategoriesData);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    setGetId(item.id);
    axios
      .get(headApi + `/category/size-mm?catid=${item.id}&userid=1`)
      .then((res) => setItems1(res.data.cat1SizemmData));
  };

  const handleSimple1 = async (event, id, prop) => {
    var tempData = {};
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id == value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    const apiCall = await fetch(
      headApi + `/category/labour?cat1id=${itemm.id}&userid=1`
    );
    const labour = await apiCall.json();
    const userUsed = labour.cat2labourData.map((d) => d?.id);
    Promise.all(
      userUsed.map(async (id) => {
        const usedApi = await fetch(headApi + `/products/labourCount/${id}`);
        const data = await usedApi.json();
        // console.log(data);
        tempData = { ...tempData, [id]: data };
      })
    ).then(() => {
      // console.log(tempData);
      const tempItems2 = labour.cat2labourData.map((i) => {
        return { ...i, used: tempData[i.id] || 0 };
      });
      setItems2(tempItems2);
    });
  };

  const handleLabour = (index) => (e) => {
    let description = e.target.value;
    setItems2((curlab) =>
      produce(curlab, (v) => {
        v[index].description = description;
      })
    );
  };

  const handleHSNCode = (index) => (e) => {
    let hsncode = e.target.value;
    setItems2((curlab) =>
      produce(curlab, (v) => {
        v[index].hsncode = hsncode;
      })
    );
  };

  const handlePrice = (index) => (e) => {
    let price = e.target.value;
    setItems2((curlab) =>
      produce(curlab, (v) => {
        v[index].price = price;
      })
    );
  };

  const handleGst = (index) => (e) => {
    let ligst = e.target.value;
    setItems2((curlab) =>
      produce(curlab, (v) => {
        v[index].ligst = ligst;
      })
    );
  };

  const SubmitUpdateLabourRate = () => {
    setBtn(true);
    const newList =
      items2 &&
      items2.map((d) => {
        return {
          id: d.id,
          cat1id: d.cat1id,
          userid: d.userid,
          description: d.description,
          hsncode: d.hsncode,
          price: d.price,
          ligst: d.ligst,
        };
      });
    // console.log(JSON.stringify(newList));
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(newList),
    };
    fetch(headApi + "/category/labourlist", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.reload(false);
      });
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setItems2("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update All Labour & Rate</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {items2 &&
          items2.map((Ele, index) => (
            <GridItem xs={12} sm={12} md={8} key={Ele.id} value={Ele.id}>
              <Card>
                <CardHeader color="rose" icon>
                  {Ele.used !== 0 ? (
                    <CardIcon color="rose">
                      Used : {Ele.used !== 0 ? Ele.used : ""}
                    </CardIcon>
                  ) : (
                    ""
                  )}
                  {/* <h3 className={classes.cardIconTitle}>
                  <small>Select Design Images</small>
                </h3> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={"Labour Name..." + Ele.id}
                        id="description"
                        inputProps={{
                          placeholder: "Labour Name...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="description"
                        value={Ele.description}
                        onChange={handleLabour(index)}
                      />
                    </GridItem>

                    <GridItem xs={4} sm={4} md={4}>
                      <CustomInput
                        labelText="HSN Code"
                        id="hsncode"
                        inputProps={{
                          placeholder: "HSN Code...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="hsncode"
                        value={Ele.hsncode}
                        onChange={handleHSNCode(index)}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <CustomInput
                        labelText="Price"
                        id="price"
                        inputProps={{
                          placeholder: "Price...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="price"
                        value={Ele.price}
                        onChange={handlePrice(index)}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <CustomInput
                        labelText="GST @5%"
                        id="ligst"
                        inputProps={{
                          placeholder: "GST @5%...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="ligst"
                        value={Ele.ligst}
                        onChange={handleGst(index)}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        <GridItem xs={12} sm={12} md={8}>
          {/* <Card> */}
          <CardBody>
            <GridContainer></GridContainer>
            {/* <Button
              color="rose"
              onClick={SubmitUpdateLabourRate}
              className={classes.updateProfileButton}
            >
              Update
            </Button> */}
            {btn === true ? (
              <Button className={classes.updateProfileButton}>Update</Button>
            ) : (
              <Button
                color="rose"
                onClick={SubmitUpdateLabourRate}
                className={classes.updateProfileButton}
              >
                Update
              </Button>
            )}
          </CardBody>
          {/* </Card> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
