import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import CardIcon from "components/Card/CardIcon.js";

// material-ui icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Store from "@material-ui/icons/Store";

import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
// import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styDrop);
const useStyl = makeStyles(style);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function OrderPandingAmount() {
  const [addCart, setAddCart] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classeAlert = useStyl();
  const classeDailog = useStyleDailog();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getOrderPendingAmount() {
    const api = localApi + `/orders/list/byAdminIdwithZero/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);
    if (getResult.length == 0) {
        setSubmitted(true);
        alertMsgOps("Data Not Found");
      } else {
        setAddCart(getResult && getResult);
      }
  }

  useEffect(() => {
    getOrderPendingAmount();
  }, []);


  const hideAlert = () => {
    setSubmitted(false);
    history.push("/admin/dashboard")
    // window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
      <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
              <Store />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Order Pending Amount</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
      </GridContainer>

      <GridContainer>
        {addCart &&
          addCart.map((c) => (
            <GridItem xs={12} sm={6} md={4} key={c.id}>
              <Card>
                <CardHeader color="rose" icon></CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4 className={classes.cardTitle}>
                        {c && c.company
                          ? c.company
                          : c &&
                            c.firstName + " " +
                            c.lastName}
                      </h4>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            Order ID:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Date:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Boxs/Doze:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Bill Amount:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Balance Amount:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            status:{" "}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            Payment Status:{" "}
                          </span>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <span className={classes.OrderlistDescription}>
                            {c.id}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.createDate.slice(0, -19)}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.totalBox + " - " + "Units"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            ₹ {Math.round(c.amount) + "/-"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            ₹ {Math.round(c.balance) + "/-"}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {c.status === true ? 1 : 0}
                          </span>
                          <br />
                          <span className={classes.OrderlistDescription}>
                            {Math.round(
                              (c.payment.reduce((a, b) => a + b.received, 0) /
                                c.amount) *
                                100
                            )}
                            %
                          </span>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
