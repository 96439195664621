import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function UpdateTerms() {
  const [discription, setDiscription] = React.useState("");
  const [discriptionId, setDiscriptionId] = React.useState("");
  const [type, setType] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = useState("");
  const [alertMsg, alertMsgOps] = useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();
  if (state === null) {
    history.push("/admin/viewTerms");
  }

  async function getUpdateTerms() {
    setDiscriptionId(state && state.id);
    setDiscription(state && state.discription);
    setType(state && state.type);
  }

  useEffect(() => {
    getUpdateTerms();
  }, [state]);

  const SubmitUpdateTerms = async () => {
    setBtn(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        discription: discription,
        type: type,
      }),
    };
    fetch(
      localApi +
        `/termsAndCondition/update/byAdminAndTermsId/${
          userObj && userObj.id
        }/${discriptionId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("updated Succesfully!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    // window.location.reload(false);
    history.push("/admin/viewTerms");
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Terms & Condition</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  />
                ) : null}

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Type..."
                    id="type"
                    inputProps={{
                      placeholder: "Type...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="type"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Description..."
                    id="discription"
                    inputProps={{
                      placeholder: "Discription...",
                      multiline: true,
                      rows: 4,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="discription"
                    value={discription}
                    onChange={(e) => {
                      setDiscription(e.target.value);
                    }}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <div className={classes.center}>
                {/* <Button
                  color="rose"
                  onClick={SubmitUpdateTerms}
                  className={classes.updateProfileButton}
                >
                  update
                </Button> */}
                {btn === true ? (
                  <Button className={classes.updateProfileButton}>
                    Update
                  </Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitUpdateTerms}
                    className={classes.updateProfileButton}
                  >
                    Update
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
