import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/imgApi";
import AddIcon from '@material-ui/icons/Add';
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function AddBox(props) {
  const [boxDesc, setBoxDesc] = React.useState("");
  const [packingCharge, setPackingCharge] = React.useState("");
  const [box, setBox] = React.useState("");
  const [hsncode, setHsncode] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [ligst, setLigst] = React.useState("");
  const [brandName, setBrandName] = React.useState("");
  const [active, setActive] = React.useState(false);

  const [boxDescErr, setBoxDescErr] = React.useState("");
  const [packChargeErr, setPackchargeErr] = React.useState("");
  const [boxErr, setBoxErr] = React.useState("");
  const [hsncodeErr, setHsncodeErr] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [ligstErr, setLigstErr] = React.useState("");
  const [brandNameErr, setBrandNameErr] = React.useState("");
  const [newBox, setNewBox] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddBox = () => {
    setNewBox(true);
    let errorCount = 0;
    if (boxDesc === "" || !boxDesc) {
      errorCount++;
      setBoxDescErr("Description Name is Required");
    }
    if (brandName === "" || !brandName) {
      errorCount++;
      setBrandNameErr("Brand Name is Required");
    }

    if (packingCharge === "" || !packingCharge) {
      errorCount++;
      setPackchargeErr("Packing Labour is Required");
    }

    if (box === "" || !box) {
      errorCount++;
      setBoxErr("Box is Required");
    }

    if (hsncode === "" || !hsncode) {
      errorCount++;
      setHsncodeErr("HSN Code is Required");
    }

    if (price === "" || !price) {
      errorCount++;
      setPriceErr("Price is Required");
    }

    if (ligst === "" || !ligst) {
      errorCount++;
      setLigstErr("GST is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.id,
          boxDesc: boxDesc,
          brandName: brandName,
          pkgCharge: packingCharge,
          box: box,
          hsnCode: hsncode,
          price: price,
          tax_percentage: ligst,
        }),
      };

      fetch(localApi + "/box/add", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Box</small>
                {newBox === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {boxDescErr || brandNameErr || packChargeErr || boxErr || hsncodeErr || ligstErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Enter Box Description
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Box</SweetAlert>
                ) : null}
                {" "}
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Box Description..."
                    id="boxDesc"
                    inputProps={{
                      placeholder: "Ex. 12 Sets in 1 Box...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="boxDesc"
                    value={boxDesc}
                    onChange={(e) => {
                      setBoxDesc(e.target.value);
                      setBoxDescErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{boxDescErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Brand Name..."
                    id="brandName"
                    inputProps={{
                      placeholder: "Ex. Omega Bangles...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="brandName"
                    value={brandName}
                    onChange={(e) => {
                      setBrandName(e.target.value);
                      setBrandNameErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{brandNameErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Box Packing Labour..."
                    id="packingCharge"
                    inputProps={{
                      placeholder: "Ex. 12...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="packingCharge"
                    value={packingCharge}
                    onChange={(e) => {
                      setPackingCharge(e.target.value);
                      setPackchargeErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{packChargeErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Box 6/12/18..."
                    id="box"
                    inputProps={{
                      placeholder: "Ex. 12...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="box"
                    value={box}
                    onChange={(e) => {
                      setBox(e.target.value);
                      setBoxErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{boxErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="HSN Code..."
                    id="hsncode"
                    inputProps={{
                      placeholder: "Ex. 4819...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsncode"
                    value={hsncode}
                    onChange={(e) => {
                      setHsncode(e.target.value);
                      setHsncodeErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{hsncodeErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Price..."
                    id="price"
                    inputProps={{
                      placeholder: "Ex. 8.50...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{priceErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="GST @18%..."
                    id="ligst"
                    inputProps={{
                      placeholder: "Ex. 18...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="ligst"
                    value={ligst}
                    onChange={(e) => {
                      setLigst(e.target.value);
                      setLigstErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{ligstErr}</span> */}
                </GridItem>
              </GridContainer>
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddBox}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
              {/* <Button
                color="rose"
                onClick={SubmitAddBox}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
AddBox.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
