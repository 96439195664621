import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { localApi } from "utils/imgApi";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);
const useStyleSweet = makeStyles(styleSweet);

export default function AddMetal(props) {
  const [metdesc, setMetalDesc] = React.useState("");
  const [different, setDifferent] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [metErr, setMetErr] = React.useState("");
  const [differentErr, setDifferentErr] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [gstErr, setGstErr] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [newMetal, setNewMetal] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddMetal = () => {
    setNewMetal(true);
    let errorCount = 0;
    if (metdesc === "" || !metdesc) {
      errorCount++;
      setMetErr("Pipe/Golwire is Required");
    }

    if (different === "" || !different) {
      errorCount++;
      setDifferentErr("Different Price is Required");
    }

    if (price === "" || !price) {
      errorCount++;
      setPriceErr("Price is Required");
    }

    if (gst === "" || !gst) {
      errorCount++;
      setGstErr("GST is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.id,
          title: metdesc,
          different: different,
          price: price,
          taxPercentage: gst,
        }),
      };

      fetch(localApi + "/rawprice/add", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Raw Metal</small>
                {newMetal === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {metErr || differentErr || priceErr || gstErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Enter Pipe/Golwire
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Raw Metal</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Pipe/Golwire Thaw..."
                    id="metdesc"
                    inputProps={{
                      placeholder: "Ex. Rolling Dall Patti/Lasiya...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metdesc"
                    value={metdesc}
                    onChange={(e) => {
                      setMetalDesc(e.target.value);
                      setMetErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{metErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Metal Price..."
                    id="price"
                    inputProps={{
                      placeholder: "Ex. 610...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{priceErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Different Price..."
                    id="different"
                    inputProps={{
                      placeholder: "Ex. 8...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="different"
                    value={different}
                    onChange={(e) => {
                      setDifferent(e.target.value);
                      setDifferentErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{differentErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="GST @18%..."
                    id="gst"
                    inputProps={{
                      placeholder: "Ex. 18...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gst"
                    value={gst}
                    onChange={(e) => {
                      setGst(e.target.value);
                      setGstErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{gstErr}</span> */}
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddMetal}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddMetal}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
