import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { localApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import Badge from "components/Badge/Badge.js";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Selects from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styleDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleDrop = makeStyles(styleDrop);
const useHoever = makeStyles(styHoever);
const profileStyl = makeStyles(profileStyle);

export default function ViewTerms() {
  const [itemsClient, setItemsClient] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [discription, setDiscription] = React.useState("");
  const [discriptionId, setDiscriptionId] = React.useState("");
  const [type, setType] = React.useState("");
  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [clientid, setClientid] = React.useState("");
  const [simpleSelectClient, setSimpleSelectClient] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyleDrop();
  const classeHover = useHoever();
  const history = useHistory();
  const classProfile = profileStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(api);
    const getResults = await results.json();
    setItemsClient(getResults && getResults.filter((f) => f.type != "Crm"));
    // console.log(getResults);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleBoots = async (simpleSelectClient) => {
    if (simpleSelectClient.value) {
      let newSpli = simpleSelectClient.value.split("-");
      const ids = itemsClient.find((f) => f.mobile === newSpli[1]);
      setClientid(ids && ids);
      const api =
        localApi +
        `/termsAndCondition/byAdminAndClientId/${userObj && userObj.id}/${
          ids.id
        }`;
      const result = await fetch(api);
      const getResult = await result.json();
      //   console.log(getResult);
      setItems(getResult && getResult);
    }
    setSimpleSelectClient(simpleSelectClient);
  };

  const handleDeleteTerm = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    // console.log(cartId.id);

    fetch(
      localApi + `/termsAndCondition/delete/ById/${cartId.id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Terms & Condition</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  />
                ) : null}
                {submitteds ? (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "100px" }}
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                    cancelBtnCssClass={classe.button + " " + classe.danger}
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                  />
                ) : null}

                {submittedConfirm ? (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlerts()}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  >
                    Your item has been deleted.
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Company & Client Name
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Company & Client Name..."
                    value={simpleSelectClient}
                    name="simpleSelectClient"
                    onChange={handleBoots}
                    options={
                      itemsClient &&
                      itemsClient.map((c) => {
                        return {
                          label:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          {items &&
            items.map((a) => (
              <GridItem xs={12} sm={4} md={4} key={a.id}>
                <Card>
                  <CardHeader color="rose" icon></CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p className={classProfile.cardDescription}>
                          <Badge color="rose">{a.type}</Badge>
                          <small
                            style={{
                              float: "right",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip
                              id="tooltip-top"
                              title="Edit"
                              placement="bottom"
                              classes={{ tooltip: classeHover.tooltip }}
                            >
                              <Button
                                color="rose"
                                simple
                                justIcon
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin/updateTerms`,
                                    state: a,
                                  })
                                }
                              >
                                <Edit className={classeHover.underChartIcons} />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top"
                              title="Remove"
                              placement="bottom"
                              classes={{ tooltip: classeHover.tooltip }}
                            >
                              <Button
                                color="danger"
                                simple
                                justIcon
                                onClick={() => {
                                  handleDeleteTerm(a);
                                }}
                              >
                                <DeleteForeverIcon
                                  className={classeHover.underChartIcons}
                                />
                              </Button>
                            </Tooltip>
                          </small>
                        </p>
                        <p className={classProfile.cardDescription}>
                          {a.discription}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridContainer>
    </div>
  );
}
