import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function AddLabourRate(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleSelect1Err, setSimpleSelect1Err] = React.useState("");
  const [labourDetails, setLabourDetails] = React.useState("");
  const [labourErr, setLabourErr] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [hsnCodeErr, setHsnCodeErr] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [gstErr, setGstErr] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [newChild, setNewChild] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api =
      localApi + `/category/list/byAdminId/${adminObj && adminObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItems(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    setGetId(item.id);
    setItems1(item.parent);
    setSimpleSelectErr("");
  };

  const handleSimple1 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id == value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    setSimpleSelect1Err("");
  };

  const SubmitAddLabourRate = (e) => {
    setNewChild(true);
    let errorCount = 0;
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectErr("Select Category");
    }
    if (simpleSelect1 === "" || !simpleSelect1) {
      errorCount++;
      setSimpleSelect1Err("Select Product Size MM");
    }
    if (labourDetails === "" || !labourDetails) {
      errorCount++;
      setLabourErr("This Labour Title Field Required");
    }
    if (hsnCode === "" || !hsnCode) {
      errorCount++;
      setHsnCodeErr("This HSN Code Field Required");
    }
    if (price === "" || !price) {
      errorCount++;
      setPriceErr("This Price Field Required");
    }
    if (gst === "" || !gst) {
      errorCount++;
      setGstErr("This GST 5% Field Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          title: labourDetails,
          hsncode: hsnCode,
          price: price,
          taxPercentage: gst,
        }),
      };
      fetch(
        localApi + `/child/add/ByAdminAndParentId/${adminObj.id}/${getcatId}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.error == true) {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          } else {
            alertTypeOps("danger");
            alertMsgOps("Already Exits");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Labour & Rate</small>
                {newChild === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectErr ||
                      simpleSelect1Err ||
                      labourErr ||
                      hsnCodeErr ||
                      priceErr ||
                      gstErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Select Category
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Labour & Rate</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Title...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Title...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Labour Title..."
                    id="labourDetails"
                    inputProps={{
                      placeholder: "Ex. Cutting...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="labourDetails"
                    value={labourDetails}
                    onChange={(e) => {
                      setLabourDetails(e.target.value);
                      setLabourErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{labourErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="HSN Code..."
                    id="hsnCode"
                    inputProps={{
                      placeholder: "Ex. 998893...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsnCode"
                    value={hsnCode}
                    onChange={(e) => {
                      setHsnCode(e.target.value);
                      setHsnCodeErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{hsnCodeErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Price..."
                    id="price"
                    inputProps={{
                      placeholder: "Ex. 1.50...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{priceErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="TAX @5%..."
                    id="gst"
                    inputProps={{
                      placeholder: "Ex. 5...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gst"
                    value={gst}
                    onChange={(e) => {
                      setGst(e.target.value);
                      setGstErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{gstErr}</span> */}
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddLabourRate}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddLabourRate}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
