import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { localApi } from "utils/imgApi";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyleSweet = makeStyles(styleSweet);

export default function AddCategory(props) {
  // const headApi = `https://api.bangles.org.in/api/rest`;

  const [categoryName, setCategoryName] = React.useState("");
  const [categoryNameErr, setCategoryNameErr] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [newCategory, setNewCategory] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyleSweet();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddCategory = () => {
    setNewCategory(true);
    let errorCount = 0;
    if (categoryName === "" || !categoryName) {
      errorCount++;
      setCategoryNameErr("Select Category");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: adminObj.id,
          title: categoryName,
        }),
      };

      fetch(localApi + "/category/add/findByTitle/byAdminId", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.error == true) {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          } else {
            alertTypeOps("danger");
            alertMsgOps("Already Exits");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Category</small>
                {newCategory === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {categoryNameErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Enter Category
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Product Category</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Category Title..."
                    id="categoryName"
                    inputProps={{
                      placeholder: "Ex. Category Title...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="categoryName"
                    value={categoryName}
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                      setCategoryNameErr("");
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddCategory}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddCategory}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
AddCategory.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
